import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import ScoreOutlinedIcon from "@material-ui/icons/ScoreOutlined";
import CodeIcon from "@material-ui/icons/Code";
//import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/aboutMeStyle.js";

const useStyles = makeStyles(styles);

export default function AboutMeSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>About Me</h2>
          <h5 className={classes.description}>
            I{"'"}m a student, graduating from high school in 2023. I compete in
            CyberPatriot, multiple Cyber-CTFs, such as the{" "}
            <a href="https://www.nationalcyberscholarship.org/">NCSC</a>,{" "}
            <a href="https://ctf.redpwn.net/">redpwnCTF</a>, and{" "}
            <a href="https://cybersecurity.tamu.edu/event/tamuctf-2021/">
              TAMUCTF
            </a>
            . I am proficient in both offensive and defensive security,
            especially with Windows. I also know multiple coding languages, such
            as Java, Go, and JavaScript. In my free time, I code plugins for
            Minecraft, and run a{" "}
            <a href="/minecraft-server">Minecraft server</a> employing many of
            my plugins.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="Competition History"
              description="I have competed in CyberPatriot IX, XII, and XIII, and am currently competing in CyberPatriot XIV.
              In both XII and XIII, I have been 3rd in the Platinum tier for my state. I have also scored highly in many other CTFs and challenges,
              such as the NCSC, of which I was tied for 69th place in. I also compete in HackTheBox, and have completed many boxes there."
              icon={ScoreOutlinedIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="Programming History"
              description="I am proficient in the languages of Java, JavaScript (and variants such as VueJS and TypeScript),
              HTML, CSS, and Go. I am also capable with the system scripting languages of both Batch (Windows),
              Bash (Linux), and PowerShell (both but mostly Windows). My programming services can be hired for any needs, just
              contact me through Discord or Matrix."
              icon={CodeIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

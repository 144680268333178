import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";

import btd6 from "assets/img/btd6.webp";
import classified from "assets/img/classified.webp";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <div style={{margin: "0px", display: "block"}}>
                  <GridContainer>
                    <img style={{margin: "15px", marginLeft: "30px", borderRadius: "15px"}}
                         src={btd6} alt="Bloons TD 6 logo" width="15%" height="auto"/>
                    <GridContainer>
                      <GridItem style={{marginTop: "0px"}}>
                        <h3>Bloons TD 6 Theme</h3>
                      </GridItem>
                      <GridItem style={{marginTop: "-20px"}}>
                        <p><b>Version:</b> Debian 10</p>
                        <p style={{marginTop: "-15px"}}><b>Difficulty:</b> 0</p>
                        <p style={{marginTop: "-15px"}}><b>Aced By:</b> kmp3e</p>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                  <GridContainer style={{marginLeft: "0px"}}>
                    <GridItem>
                      <div style={{}}>
                        <p>This is a beginner-level image, designed to help with the basics of Linux.</p>
                        <pre>
                          Username: dartmonkey
                        </pre>
                        <pre>
                          Password: monkey123!
                        </pre>
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem style={{marginLeft: "10px", marginBottom: "55px"}}>
                      <div style={{display: "block"}}>
                        <Button
                          href="https://drive.google.com/file/d/1-QN2GjowfgRHPnI9JibqwZiyx-mGgCzT/view?usp=sharing"
                          target="_blank"
                          rel="noreferrer"
                          color="primary" margin="15px">Download</Button>
                        <Button
                          href="https://drive.google.com/file/d/15bA9T38Lq7FYhLNJM2CkNV3hUXaga9WL/view?usp=sharing"
                          target="_blank"
                          rel="noreferrer"
                          color="primary">Answer Key</Button>
                        <Button disabled={true}>Walkthrough</Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
                <div style={{margin: "0px"}}>
                  <GridContainer>
                    <img style={{margin: "15px", marginLeft: "30px", borderRadius: "15px"}}
                         src={classified} alt="Classified" width="15%" height="auto"/>
                    <GridContainer>
                      <GridItem style={{marginTop: "0px"}}>
                        <h3>Classified</h3>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </div>
                <div style={{margin: "0px"}}>
                  <GridContainer>
                    <img style={{margin: "15px", marginLeft: "30px", borderRadius: "15px"}}
                         src={classified} alt="Classified" width="15%" height="auto"/>
                    <GridContainer>
                      <GridItem style={{marginTop: "0px"}}>
                        <h3>Classified</h3>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

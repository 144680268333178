/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import {List, ListItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const {whiteFont} = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const bClasses = classNames({
    [classes.b]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="foot-matrix"
                title="@realmobmaker:matrix.org"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{tooltip: classes.tooltip}}
              >
                <a
                  className={classes.block}
                >
                  Matrix
                </a>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="foot-discord"
                title="@Mobmaker#1579"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{tooltip: classes.tooltip}}
              >
                <a
                  className={classes.block}
                >
                  Discord
                </a>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="foot-bonus"
                title="Mobmaker"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{tooltip: classes.tooltip}}
              >
                <a
                  href="https://steamcommunity.com/id/Mobmaker/"
                  className={classes.block}
                  target="_blank"
                >
                  Steam
                </a>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="foot-email"
                title="E-mail server not up :("
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{tooltip: classes.tooltip}}
              >
                <a
                  className={classes.block}
                >
                  E-Mail
                </a>
              </Tooltip>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy;{1900 + new Date().getYear()}{" "}
          <a
            href="https://www.creative-tim.com?ref=mkr-footer"
            className={aClasses}
            target="_blank"
          >
            Creative Tim
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
